import React from 'react'

function Footer() {
    return (
        <div className="heading5 pt-5">
            <div>
                <p className='text-center fontf' >Contact/Whatsapp at 8697317359 for queries</p>
            </div>
            <div className='text-center'><i className='fa fa-copyright'></i> Rohan Ray</div>
        </div>
    )
}

export default Footer